// 注意：一定是 exports，不是 export，否则会报错，报错信息是下列的中的内容不是 string
module.exports = {
  topBarContent: {
    company: 'BETS Sport',
    hotLeague: 'Hottest Leagues',
    business: 'Business Cooperation',
    join: 'Join Us',
    serve: 'Service Process',
  },
  businessContent: {
    title: `BETS's Sport`,
    contentTitle:`BETS's leading one-stop service in the industry allows you to win at the starting point, taking its first place in Southeast Asia.`,
    content: `
    BETF Sports has more than 20 years of industry experience. It gathers talents in technology development, brand marketing, art vision, operations and business promotion. It has tailor-made multiple sets of templates for the Southeast Asian market. The front and back end supports various languages and currencies. The platform integrates key technologies, provide a full range of services such as operation management, web development, mobile app development, and API interface. A variety of sports items are readily available, and it also provides one-stop full service for corporate customers. BETF Sports colleagues provide you with the most abundant and high-quality e-sports products. More than 5,000 e-sports and online events are held every month, allowing customers to instantly have the most competitive e-sports portfolio.
    BeTF Sports is the first choice for Asian Gamers and invites you to create brilliance and surpass the peak together. `,
  },
  list: {
    listfir:
      'The most complete events, supported by renowned sports data providers',
    listsec: `The world's strongest and skillful, sports trader elite team`,
    listthi: 'Rich gameplay, choose and bet as freely as you like',
    listfor:
      'The most markets, the original in-play events, breaking the tradition, the three-decimal odds are exquisitely calculated',
    listfif:
      'Place a bet within a second, prizes award instantly, leading the world',
    listsix: 'Top-level protection, absolutely safe and smooth experience',
  },
  btncontent: 'Contact us',
  hotLeagueContent: {
    title: 'Hottest Leagues',
    football: 'Top 5 Football Leagues',
    nba: 'NBA Sports Events',
    Tennis: 'Top 4 Tennis Open Tournaments',
    Volleyball: 'World Volleyball Championship',
    Table: 'World Table Tennis Championship',
    Virtual: 'Virtual Sports',
  },
  businessCooperation: {
    title: 'Business Cooperation',
    content:
      'BETF Sports provides diversified services for our partners, including cash net one-stop package network, credit net package network, credit commissions netwotk cooperation, API interface docking, simple and easy to connect, can be connected within one day. We also provide a variety of cooperation programs and BETF Sports will actively respond to any mutually beneficial plan for both parties to cooperate. We will solve all the technical problems for you and let you having full focus on operations. ',
    listfir: 'Subvert the industry style and diversify solutions',
    listsec: 'API interface docking',
    listthi: 'Credit package network',
    listfor: 'Credit network with fully self operation',
    listfif: 'Any mutually beneficial plan',
    listsix: '7*24 hours exclusive service',
  },
  findBusiness: {
    title: 'Service Process',
    Consult: 'Consult for the preferential plan',
    contract: 'Sign the contract plan',
    representation: 'Choose the exclusive representation version',
    modification: 'Proposal production modification',
    online: 'Formal online operation',
    maintenance: 'Follow-up site maintenance',
  },
  footerContent: {
    attract: 'Cooperation',
    content: 'Please click the picture directly for consultation',
  },
}
