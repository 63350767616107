<template>
  <div>
    <div id="3" class="findBusinessWraper">
      <div class="findLeft">
        <img class="imgPerson" src="../assets/findPerson.png" alt />
      </div>
      <div class="findRight">
        <img class="imgText" src="../assets/findText.png" alt />
      </div>
    </div>
    <contactMessage />
    <div id="4" class="contentFooter">
      <div class="footerText">{{$t("findBusiness.title")}}</div>
      <!-- <div style="display:flex;justify-content:center"> -->
      <div class="bottomImgwraper">
        <div class="flowImgWraper">
          <div class="ImgWraper">
            <div class="iconWraper">
              <img class="iconImg" src="../assets/service.png" alt />
              <div class="iconimgText">{{$t("findBusiness.Consult")}}</div>
            </div>
            <span class="iconWraper arriowsWraper">
              <img class="arriwsImg" src="../assets/doubleArrows.png" alt />
            </span>
          </div>
        </div>
        <div class="flowImgWraper">
          <div class="ImgWraper">
            <div class="iconWraper">
              <img class="iconImg" src="../assets/order.png" alt />
              <div class="iconimgText">{{$t("findBusiness.contract")}}</div>
            </div>
            <span class="iconWraper arriowsWraper">
              <img class="arriwsImg" src="../assets/doubleArrows.png" alt />
            </span>
          </div>
        </div>
        <div class="flowImgWraper">
          <div class="ImgWraper">
            <div class="iconWraper">
              <img class="iconImg" src="../assets/setting.png" alt />
              <div class="iconimgText">{{$t("findBusiness.representation")}}</div>
            </div>
            <span class="iconWraper arriowsWraper">
              <img class="arriwsImg" src="../assets/doubleArrows.png" alt />
            </span>
          </div>
        </div>
        <div class="flowImgWraper">
          <div class="ImgWraper">
            <div class="iconWraper">
              <img class="iconImg" src="../assets/update.png" alt />
              <div class="iconimgText">{{$t("findBusiness.modification")}}</div>
            </div>
            <span class="iconWraper arriowsWraper">
              <img class="arriwsImg" src="../assets/doubleArrows.png" alt />
            </span>
          </div>
        </div>
        <div class="flowImgWraper">
          <div class="ImgWraper">
            <div class="iconWraper">
              <img class="iconImg" src="../assets/operation.png" alt />
              <div class="iconimgText">{{$t("findBusiness.online")}}</div>
            </div>
            <span class="iconWraper arriowsWraper">
              <img class="arriwsImg" src="../assets/doubleArrows.png" alt />
            </span>
          </div>
        </div>
        <div class="flowImgWraper">
          <div class="ImgWraper">
            <div class="iconWraper">
              <img class="iconImg" src="../assets/maintain.png" alt />
              <div class="iconimgText">{{$t("findBusiness.maintenance")}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 移动端样式 -->
      <div class="phoneBottom">
        <div class="flowImgWraper">
          <div class="ImgWraper">
            <div class="iconWraper">
              <img class="iconImg" src="../assets/service.png" alt />
              <div class="iconimgText">{{$t("findBusiness.Consult")}}</div>
            </div>
            <span class="iconWraper arriowsWraper">
              <img class="arriwsImg" src="../assets/doubleArrows.png" alt />
            </span>
            <div class="iconWraper">
              <img class="iconImg" src="../assets/order.png" alt />
              <div class="iconimgText">{{$t("findBusiness.contract")}}</div>
            </div>
          </div>
        </div>
        <div class="dowArriows">
          <span class="iconWraper arriowsWraper donwFlex">
            <img class="arriwsImg arriwsImgDown" src="../assets/doubleArrows.png" alt />
          </span>
        </div>
        <div class="flowImgWraper">
          <div class="ImgWraper">
            <div class="iconWraper">
              <img class="iconImg" src="../assets/setting.png" alt />
              <div class="iconimgText">{{$t("findBusiness.representation")}}</div>
            </div>
            <span class="iconWraper arriowsWraper">
              <img class="arriwsImg arrowsLeft" src="../assets/doubleArrows.png" alt />
            </span>
            <div class="iconWraper">
              <img class="iconImg" src="../assets/update.png" alt />
              <div class="iconimgText">{{$t("findBusiness.modification")}}</div>
            </div>
          </div>
        </div>
        <div class="leftdowArriows">
          <span class="iconWraper arriowsWraper leftflex">
            <img class="arriwsImg arriwsImgDown" src="../assets/doubleArrows.png" alt />
          </span>
        </div>
        <div class="flowImgWraper">
          <div class="ImgWraper">
            <div class="iconWraper">
              <img class="iconImg" src="../assets/service.png" alt />
              <div class="iconimgText">{{$t("findBusiness.Consult")}}</div>
            </div>
            <span class="iconWraper arriowsWraper">
              <img class="arriwsImg" src="../assets/doubleArrows.png" alt />
            </span>
            <div class="iconWraper">
              <img class="iconImg" src="../assets/order.png" alt />
              <div class="iconimgText">{{$t("findBusiness.contract")}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import contactMessage from './contactMessage.vue'
export default {
  components: {
    contactMessage,
  },
  data() {
    return {}
  },
}
</script>
<style scoped>
.wrapper {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.phoneBottom {
  display: none;
}
.bottomImgwraper {
  display: flex;
  justify-content: center;
  padding: 0 60px;
}
.findBusinessWraper {
  width: 80%;
  margin: 0 auto;
  background-size: cover;
  height: 680px;
  background-image: url('../assets/findBusiness.png');
  display: flex;
  justify-content: space-between;
}
.findLeft {
  width: 40%;
  display: flex;
  align-items: flex-end;
  position: relative;
  bottom: -17px;
}
.findRight {
  width: 52%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5%;
}
.imgText {
  width: 100%;
  object-fit: contain;
}
.imgPerson {
  width: 91%;
  object-fit: contain;
  position: absolute;
}
.contentFooter {
  width: 80%;
  margin: 0 auto;
  background: #062c59;
}
.footerText {
  padding: 60px 0;
  font-size: 32px;
  font-weight: bold;
  line-height: 46px;
  color: #ffffff;
  display: flex;
  justify-content: center;
}
.ImgWraper {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 100px;
}
img {
  display: block;
}
.iconWraper {
  height: 100%;
  text-align: center;
}
.arriwsImg {
  height: 50px;
}
.iconImg {
  width: 100%;
  margin: 0 auto;
}
.arriowsWraper {
  animation: right-pig-move 2s -2s infinite alternate-reverse;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 136px;
  width: 200px;
}
.iconimgText {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  padding-top: 20px;
}
.company {
  line-height: 40px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  padding-bottom: 30px;
}
@keyframes up-to-down {
  0% {
    /* -webkit-transform: translate3d(0, 0, 0); */
    transform: translateY(5px) ：仅仅是在X轴上移动;
  }
  50% {
    /* -webkit-transform: translate3d(30px, 0, 0); */
    transform: translate3d(0, 18px, 0);
  }
}
@keyframes right-pig-move {
  0% {
    /* -webkit-transform: translate3d(0, 0, 0); */
    transform: translateX(5px) ：仅仅是在X轴上移动;
  }
  50% {
    /* -webkit-transform: translate3d(30px, 0, 0); */
    transform: translate3d(18px, 0, 0);
  }
}
@media screen and (max-width: 640px) {
  .findBusinessWraper{
    width: 100%;
  }
  .company {
    display: none;
  }
  .bottomImgwraper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .iconImg {
    display: block;
    width: 350px;
    height: 350px;
  }
  .arriowsWraper {
    height: 300px;
    margin-right: 0px;
  }
  .arriwsImg {
    height: 100px;
  }
  .iconimgText {
    line-height: 100px;
    text-align: center;
  }
  .footerText {
    font-size: 100px;
    line-height: 180px;
  }
  .bottomImgwraper {
    display: none;
  }
  .phoneBottom {
    display: block;
    padding-bottom: 100px;
  }
  .flowImgWraper {
    margin: 0 250px;
  }

  .ImgWraper {
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
  }
  .arriwsImgDown {
    transform: rotate(90deg);
  }
  .dowArriows {
    display: flex;
    justify-content: flex-end;
    margin: 0 400px;
  }
  .leftdowArriows {
    margin: 0 400px;
  }
  .arrowsLeft {
    transform: rotate(180deg);
  }
  .donwFlex {
    display: flex;
    justify-content: flex-end;
  }
  .leftflex {
    justify-content: flex-start;
  }
  .donwFlex {
    animation: up-to-down 2s -2s infinite alternate-reverse;
  }
  .leftflex {
    animation: up-to-down 2s -2s infinite alternate-reverse;
  }
  .iconWraper {
    min-width: 450px;
  }
  .contentFooter {
    width: 100%;
    z-index: 998;
    position: relative;
  }
}

</style>
