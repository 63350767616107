<template>
  <div id="app">
    <indexs />
    <company />
    <hotLeague />
    <business />
    <findBusiness />
  </div>
</template>

<script>
import indexs from './components/indexs.vue'
import hotLeague from './components/hotLeague.vue'
import company from './components/company.vue'
import business from './components/business.vue'
import findBusiness from './components/findBusiness.vue'

export default {
  name: 'App',
  components: {
    indexs,
    hotLeague,
    company,
    business,
    findBusiness,
  },
}
</script>

<style>
html,
body,
#app {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-color: #002046;
}
button {
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
}
.bottomText {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 600;
  color: #001726;
}
.contentBottom {
  padding-bottom: 30px;
  display: flex;
}
.squareWarper {
  margin-top: 6px;
  margin-right: 30px;
  padding-left: 10px;
}
.square {
  width: 17px;
  height: 17px;
  background: #ff5318;
  transform: rotate(45deg); /* Equal to rotateZ(45deg) */
  justify-content: center;
}
.animationWraper{
    position: absolute;
  height: 80%;
  width: 100%;
  z-index: 1;
}
.contentWarper {
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  padding: 100px;
}
.animationImgCompany{
  position: relative;
  height: 100%;
  width: 100%;
  background-image:url('./assets/backgroundImg01.png') ;
  background-size:contain;
  background-repeat: no-repeat;
  animation:scaleDraw 10s infinite ease-in-out;
  z-index: -1;
  overflow: hidden;
}
@keyframes scaleDraw {
	/*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    transform:scale(0.5);
    /*开始为原始大小*/
  }
  100% {
    transform:scale(2.0);
  }
}

</style>
