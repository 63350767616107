// 注意：一定是 exports，不是 export，否则会报错，报错信息是下列的中的内容不是 string
module.exports = {
  topBarContent: {
    company: 'BETF Sports',
    hotLeague: 'Liga Terpanas',
    business: 'Kerjasama Bisnis',
    join: `Bergabunglah dengan kami`,
    serve: 'Proses Layanan',
  },
  businessContent: {
    title: `BETF Sports`,
    contentTitle:`Layanan satu atap BETS yang terkemuka di industri memungkinkan Anda untuk menang di titik awal, menempati tempat pertama di Asia Tenggara.`,
    content: `
  BETF Sports memiliki pengalaman industri lebih dari 20 tahun. Ini mengumpulkan bakat dalam pengembangan teknologi, pemasaran merek, visi seni, operasi, dan promosi bisnis. Ini memiliki beberapa set template yang dibuat khusus untuk pasar Asia Tenggara. Bagian depan dan belakang mendukung berbagai bahasa dan mata uang. Platform ini mengintegrasikan teknologi utama, menyediakan berbagai layanan seperti manajemen operasi, pengembangan web, pengembangan aplikasi seluler, dan antarmuka API. Berbagai item olahraga sudah tersedia, dan juga menyediakan layanan lengkap satu atap untuk pelanggan korporat. Kolega BETF Sports memberi Anda produk e-sports yang paling banyak dan berkualitas tinggi. Lebih dari 5,000 e-sports dan acara online diadakan setiap bulan, mencakup lebih dari 25 game e-sports mainstream seperti CS: GO, Dota 2 dan League of Legends, memungkinkan pelanggan untuk langsung memiliki portofolio e-sports paling kompetitif.
  BeTF Sports adalah pilihan pertama bagi Gamer Asia dan mengundang Anda untuk menciptakan kecerdasan dan melampaui puncak bersama. `,
  },
  list: {
    listfir: 'Acara terlengkap, didukung oleh penyedia data olahraga ternama',
    listsec: 'Tim elit pedagang olahraga terkuat dan terampil di dunia',
    listthi: 'Gameplay yang kaya, pilih dan bertaruh sebebas yang Anda suka',
    listfor:
      'Sebagian besar pasar, peristiwa dalam-permainan asli, melanggar tradisi, peluang tiga desimal dihitung dengan tepat',
    listfif:
      'Pasang taruhan dalam hitungan detik, berikan hadiah secara instan, penyelesaian di muka, memimpin dunia',
    listsix:
      'Perlindungan tingkat atas, pengalaman yang benar-benar aman dan lancar',
  },
  btncontent: `
  hubungi kami`,
  hotLeagueContent: {
    title: 'Liga Terpanas',
    football: '5 Liga Sepak Bola Teratas',
    nba: 'Acara Olahraga NBA',
    Tennis: '4 Turnamen Tenis Terbuka Teratas',
    Volleyball: 'Kejuaraan Bola Voli Dunia',
    Table: 'Kejuaraan Tenis Meja Dunia',
    Virtual: 'Olahraga Virtual',
  },
  businessCooperation: {
    title: 'Kerjasama Bisnis',
    content: `BETF Sports menyediakan berbagai layanan untuk mitra kami, termasuk jaringan paket tunai satu atap, jaringan paket kredit, kerjasama jaringan komisi kredit, docking antarmuka API, sederhana dan mudah untuk dihubungkan, dapat dihubungkan dalam satu hari. Kami juga menyediakan berbagai program kerjasama dan BETF Sports akan secara aktif merespon setiap rencana yang saling menguntungkan untuk kerjasama kedua belah pihak. Kami akan menyelesaikan semua masalah teknis untuk Anda dan memungkinkan Anda memiliki fokus penuh pada pengoperasian.`,
    listfir: 'Menumbangkan gaya industri dan melakukan diversifikasi solusi',
    listsec: 'Pengaitan antarmuka API',
    listthi: 'Jaringan paket kredit',
    listfor: 'Jaringan kredit dengan operasi mandiri sepenuhnya',
    listfif: 'Rencana apa pun yang saling menguntungkan',
    listsix: 'Layanan eksklusif 7 * 24 jam',
  },
  findBusiness: {
    title: 'Proses Layanan',
    Consult: 'Konsultasikan untuk rencana preferensial',
    contract: 'Tanda tangani rencana kontrak',
    representation: 'Pilih versi representasi ',
    modification: 'Modifikasi produksi proposal',
    online: 'pemeliharaan situs tindak lanjut',
    maintenance: 'operasi online formal',
  },
  footerContent: {
    attract: `Kerja sama`,
    content: 'Silakan klik gambar secara langsung untuk konsultasi',
  },
}
