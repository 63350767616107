<template>
  <div>
    <div id="1" class="imageModuleWarper">
      <div class="imageModule">
        <div class="imageTop">
          <div class="imgTopcontent">{{$t("hotLeagueContent.title")}}</div>
        </div>
        <div class="imgwarper">
          <div class="imgflexBox">
            <img src="../assets/image01.png" alt />
            <div class="imageContent">{{$t("hotLeagueContent.football")}}</div>
          </div>
          <div class="imgflexBox">
            <img src="../assets/image02.png" alt />
            <div class="imageContent">{{$t("hotLeagueContent.nba")}}</div>
          </div>
          <div class="imgflexBox">
            <img src="../assets/image03.png" alt />
            <div class="imageContent">{{$t("hotLeagueContent.Tennis")}}</div>
          </div>
          <div class="spilt"></div>
          <div class="imgflexBox">
            <img src="../assets/image04.png" alt />
            <div class="imageContent">{{$t("hotLeagueContent.Volleyball")}}</div>
          </div>
          <div class="imgflexBox">
            <img src="../assets/image05.png" alt />
            <div class="imageContent">{{$t("hotLeagueContent.Table")}}</div>
          </div>
          <div class="imgflexBox">
            <img src="../assets/image06.png" alt />
            <div class="imageContent">{{$t("hotLeagueContent.Virtual")}}</div>
          </div>
        </div>
      </div>
    </div>
    <contactMessage />
  </div>
</template>
<script>
import contactMessage from './contactMessage.vue'

export default {
  components: {
    contactMessage,
  },
}
</script>
<style scoped>
.imageModuleWarper {
  width: 80%;
  margin: 0 auto;
  background: #062c59;
  /* background: url('../assets/hotBackground.png'); */
  padding-bottom: 73px;
}
.imageModule {
  background: url('../assets/hotBackground.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.imgTopcontent {
  width: 80%;
  margin: 0 auto;
  background: url('../assets/hotLeagueImg.png') center;
  background-repeat: no-repeat;
  font-size: 30px;
  line-height: 70px;
  color: #fff;
  display: flex;
  justify-content: center;
  text-align: center;
}
.imgwarper {
  display: flex;
  padding: 70px 32px 24px 32px;
  flex-wrap: wrap;
}
.imgflexBox {
  width: 33.33%;
}
.imageContent {
  flex: 1;
  text-align: center;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 60px;
  color: #ffffff;
}
img {
  display: block;
  margin: 0 auto;
  width: 86%
}
.imageTop {
  display: flex;
  justify-content: center;
}
.spilt{
  width: 100%;
  height: 50px;
}
@media screen and (max-width: 640px) {
  .spilt{
    display: none;
  }
  .imageModuleWarper {
    width: 100%;
  }
  .imgwarper {
    display: flex;
    flex-wrap: wrap;
  }

  .imgflexBox {
    min-width: 50%;
  }
  img {
    width: 100%;
    display: block;
  }
  .imgTopcontent {
    font-size: 100px;
    line-height: 160px;
  }
  .imageContent {
    font-size: 60px;
    line-height: 120px;
  }
}
</style>
