<template>
  <div class="section0">
    <div id="0" class="wrapper">
      <div class="aniImg"></div>
      <div class="left">
        <div class="imgWraper">
          <img class="companyLeftImg" src="../assets/person2.png" alt />
        </div>
      </div>
      <div class="right">
        <div class="contentTitle">{{$t("businessContent.title")}}</div>
        <div class="contentText" style="color: #ff5318;">{{$t("businessContent.contentTitle")}}</div>
        <div class="contentText">{{$t("businessContent.content")}}</div>
        <div class="h3">
          <div class="squareWrapper">
            <div class="square1"></div>
          </div>
          <div class="text">{{$t("list.listfir")}}</div>
        </div>
        <div class="h3">
          <div class="squareWrapper">
            <div class="square1"></div>
          </div>
          <div class="text">{{$t("list.listsec")}}</div>
        </div>
        <div class="h3">
          <div class="squareWrapper">
            <div class="square1"></div>
          </div>
          <div class="text">{{$t("list.listthi")}}</div>
        </div>
        <!-- <div class="h3">
          <div class="squareWrapper">
            <div class="square1"></div>
          </div>
          <div class="text">{{$t("list.listfor")}}</div>
        </div>-->
        <div class="h3">
          <div class="squareWrapper">
            <div class="square1"></div>
          </div>
          <div class="text">{{$t("list.listfif")}}</div>
        </div>
        <div class="h3">
          <div class="squareWrapper">
            <div class="square1"></div>
          </div>
          <div class="text">{{$t("list.listsix")}}</div>
        </div>
        <div class="contactWraper">
          <button class="contactBtn" @click="toTelegram()">{{$t("btncontent")}}</button>
        </div>
      </div>
    </div>
    <contactMessage />
  </div>
</template>
<script>
import contactMessage from "./contactMessage.vue";
export default {
  components: {
    contactMessage,
  },
  data() {
    return {};
  },
  methods: {
    toTelegram() {
      window.open("https://t.me/bifatiyu88");
    },
  },
};
</script>
<style scoped>
.section0 {
  width: 100%;
  margin: 0 auto;
  margin-top: -200px;
  position: relative;
  z-index: 100;
  background-color: #002046;
}
.wrapper {
  width: 80%;
  margin: 0 auto;
  background-color: white;
  display: flex;
  padding: 128px 10px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}
.right {
  z-index: 998;
  flex: 1;
  position: relative;
  font-size: 16px;
  line-height: 1.5;
}
.h3 {
  padding-bottom: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 16px;
}
.squareWrapper {
  width: 12px;
  height: 12px;
  margin-right: 10px;
}
.square1 {
  width: 100%;
  height: 100%;
  background: #ff5318;
  transform: rotate(45deg); /* Equal to rotateZ(45deg) */
  justify-content: center;
}
.text {
  font-weight: bold;
  font-size: 16px;
}
.contentTitle {
  font-size: 32px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 46px;
  color: #001726;
}
.contentTitleText {
  color: #ff5318;
  font-size: 32px;
  line-height: 29px;
  margin: 15px 0;
}
.contentText {
  margin-bottom: 30px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 29px;
  color: #062c59;
}
.contactBtn {
  padding: 0 50px;
  cursor: pointer;
  height: 64px;
  background: linear-gradient(180deg, #06448f 0%, #032c5e 100%);
  border-radius: 6px;
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
  border: none;
  color: #ffffff;
  margin-top: 30px;
}

.left {
  width: 100%;
  flex: 1;
}
.companyLeftImg {
  height: 620px;
}
.animationWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
}

.aniImg {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url("../assets/backgroundImg01.png");
  background-size: contain;
  background-repeat: no-repeat;
  animation: scaleDraw 10s infinite ease-in-out;
  z-index: 1;
  overflow: hidden;
}

@media screen and (max-width: 640px) {
  .animationWraper {
    display: none;
  }
  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .companyLeftImg {
    width: 80%;
    height: 100%;
  }
  .imgWraper {
    display: flex;
    justify-content: center;
  }
  .contentTitle {
    font-size: 100px;
    text-align: center;
    padding: 100px 0;
  }
  .contentText {
    font-size: 60px;
    line-height: 120px;
  }
  .right {
    padding: 0 40px;
  }
  .squareWrapper {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .square1 {
    width: 100%;
    height: 100%;
    background: #ff5318;
    transform: rotate(45deg); /* Equal to rotateZ(45deg) */
    justify-content: center;
  }
  .text {
    font-size: 70px;
  }
  .contactWraper {
    width: 100%;
    z-index: 998;
  }
  .contactBtn {
    font-size: 60px;
    min-height: 160px;
    border-radius: 20px;
    display: block;
    margin-top: 30px;
    padding: 0 30px;
    min-width: 400px;
  }
  .h3 {
    line-height: 150px;
  }
}
</style>
