<template>
  <div class="outWarper">
    <div class="topBarWarper">
      <div class="logoWraper">
        <img class="logoImg" src="../assets/logo.png" alt />
      </div>
      <div class="contentWraper">
        <div
          class="content"
          :class="{active:0===numbers}"
          @click="scrollto(0)"
        >{{$t("topBarContent.company")}}</div>
        <div
          class="content"
          :class="{active:1===numbers}"
          @click="scrollto(1)"
        >{{$t("topBarContent.hotLeague")}}</div>
        <div
          class="content"
          :class="{active:2===numbers}"
          @click="scrollto(2)"
        >{{$t("topBarContent.business")}}</div>
        <div
          class="content"
          :class="{active:3===numbers}"
          style="min-width:300px"
          @click="scrollto(3)"
        >{{$t("topBarContent.join")}}</div>
        <div
          class="content"
          :class="{active:4===numbers}"
          @click="scrollto(4)"
        >{{$t("topBarContent.serve")}}</div>
      </div>
      <div class="languageWraper">
        <div class="leaguaeContent" v-for="(item,index) in languageList" :key="index">
          <div
            :class="{currentColor:changeIndex === index}"
            @click="changeLang(index)"
          >{{item.text}}</div>
        </div>
      </div>
    </div>
    <div class="swiperWarper">
      <video-player
        ref="videoPlayer"
        class="video-player vjs-custom-skin"
        :playsinline="true"
        :options="playerOptions"
      />
    </div>
    <div class="phoneTopBar">
      <div class="phoneWraper">
        <img class="logoImgWraper" src="../assets/logo.png" alt />
        <div style=" display: flex">
          <div class="imgBackios" @click="toAnd()">
            <img class="phoneImgIos" src="../assets/anzhuo.svg" alt />
            <span>安卓下载</span>
          </div>
          <div class="imgBackios" @click="toIos()">
            <img class="phoneImgIos" src="../assets/ios.svg" alt />

            <span>IOS下载</span>
          </div>
        </div>
        <div class="showMoreWraper">
          <div class="lineWraper" @click="showMoreBtn()">
            <div class="showline"></div>
            <div class="showline"></div>
            <div class="showline"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="phoneMoreWraper" v-if="showMoreList">
      <div class="moreContent">
        <div class="logoImageWarper">
          <img class="logoImage" src="../assets/logo.png" alt />
          <div class="closeWraper">
            <img class="closeImg" @click="showMoreList = false" src="../assets/close.png" alt />
          </div>
        </div>

        <div class="flexContent">
          <div
            class="content"
            :class="{active:0===numbers}"
            @click="scrollto(0)"
          >{{$t("topBarContent.company")}}</div>
          <div
            class="content"
            :class="{active:1===numbers}"
            @click="scrollto(1)"
          >{{$t("topBarContent.hotLeague")}}</div>
          <div
            class="content"
            :class="{active:2===numbers}"
            @click="scrollto(2)"
          >{{$t("topBarContent.business")}}</div>
          <div
            class="content"
            :class="{active:3===numbers}"
            style="min-width:300px"
            @click="scrollto(3)"
          >{{$t("topBarContent.join")}}</div>
          <div
            class="content"
            :class="{active:4===numbers}"
            @click="scrollto(4)"
          >{{$t("topBarContent.serve")}}</div>
        </div>
        <div class="languageWraper">
          <div class="leaguaeContent" v-for="(item,index) in languageList" :key="index">
            <div
              :class="{currentColor:changeIndex === index}"
              @click="changeLang(index)"
            >{{item.text}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="phoneContactMessage">
      <div class="imgBackGround" @click="toTelegram()">
        <img class="phoneImg" src="../assets/phoneTelegram.png" alt />
      </div>
      <div class="imgBackGround" @click="toSkype()">
        <img class="phoneImg" src="../assets/phoneSkype.png" alt />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showMoreList: false,
      numbers: "",
      changeIndex: 1,
      languageList: [
        {
          text: "EN",
          locale: "en",
        },
        {
          text: "简",
          locale: "zh",
        },
        {
          text: "TH",
          locale: "th",
        },
        {
          text: "VN",
          locale: "vn",
        },
        {
          text: "ID",
          locale: "id",
        },
      ],
      currentLanguage: this.$i18n.locale,
      playerOptions: {
        flash: {
          swf: "/video-js.swf",
        },
        autoplay: true,
        //必发体育  热门联赛  业务合作  加入我们  服务流程 'EN', '简', 'TH', 'VN', 'EN'
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        muted: true, // 默认情况下将会消除任何音频。
        loop: true, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: require("../assets/video.mp4"), // url地址
          },
        ],
        poster: "", // 你的封面地址
        width: document.documentElement.clientWidth, // 播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: true,
          fullscreenToggle: true, // 全屏按钮
        },
      },
    };
  },
  //  mounted() {
  //   // 监听滚动事件
  //   window.addEventListener('scroll', this.onScroll)
  // },
  computed: {
    player() {
      return this.$refs.videoPlayer.player; //自定义播放
    },
  },
  methods: {
    toTelegram() {
      window.open("https://t.me/bifatiyu88");
    },
    toSkype() {
      window.open("skype:live:.cid.39767e85f851ff84?chat");
    },
    toAnd() {
      window.open("https://download.betf.me/app.apk");
    },
    toIos() {
      window.open("https://xhycxn.com/s/wc1P");
    },
    //     onScroll() {
    //   // 获取所有锚点元素
    //   const navContents = document.querySelectorAll('.content div')
    //   // 所有锚点元素的 offsetTop
    //   const offsetTopArr = []
    //   navContents.forEach(item => {
    //     offsetTopArr.push(item.offsetTop)
    //   })
    //   // 获取当前文档流的 scrollTop
    //   const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    //   // 定义当前点亮的导航下标
    //   let navIndex = 0
    //   for (let n = 0; n < offsetTopArr.length; n++) {
    //     // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
    //     // 那么此时导航索引就应该是 n 了
    //     if (scrollTop >= offsetTopArr[n]) {
    //       navIndex = n
    //     }
    //   }
    //   // 把下标赋值给 vue 的 data
    //   this.active = navIndex
    // }
    //获取走马灯的索引
    getIndex(nowIndex, oldIndex) {
      //用户可能在上个视频未播放完毕就切换
      //此时需要暂停上个视频并把时间归零，否则影响对当前视频的监听逻辑
      let myVideo = this.$refs["videoPlayer" + oldIndex];
      myVideo[0].pause();
      myVideo[0].currentTime = 0;
      this.nowIndex = nowIndex;
      this.listenVideo();
    },
    //监听视频的播放、暂停、播放完毕等事件
    listenVideo() {
      //注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
      let myVideo = this.$refs["videoPlayer" + this.nowIndex];
      //监听播放
      myVideo[0].addEventListener("play", () => {
        this.autoplay = false;
        this.isPlay = true;
      });
      //监听暂停
      myVideo[0].addEventListener("pause", () => {
        this.autoplay = true;
        this.isPlay = false;
      });
      //监听播放完毕
      myVideo[0].addEventListener("ended", () => {
        //时间归零
        myVideo[0].currentTime = 0;
        this.autoplay = true;
        this.isPlay = false;
      });
    },
    //播放视频
    playVideo() {
      //注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
      let myVideo = this.$refs["videoPlayer" + this.nowIndex];
      myVideo[0].play();
    },
    //暂停视频
    pauseVideo() {
      //注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
      let myVideo = this.$refs["videoPlayer" + this.nowIndex];
      myVideo[0].pause();
    },
    //切换语言
    changeLang(value) {
      this.showMoreList = false;
      switch (value) {
        case 0:
          this.$i18n.locale = "en";
          this.changeIndex = 0;
          break;
        case 1:
          this.$i18n.locale = "zh";
          this.changeIndex = 1;
          break;
        case 2:
          this.$i18n.locale = "th";
          this.changeIndex = 2;
          break;
        case 3:
          this.$i18n.locale = "vn";
          this.changeIndex = 3;
          break;
        case 4:
          this.$i18n.locale = "id";
          this.changeIndex = 4;
          break;
        default:
      }
    },
    showMoreBtn() {
      this.showMoreList = true;
    },
    scrollto(val) {
      this.showMoreList = false;
      this.numbers = val;
      document.getElementById(val).scrollIntoView();
    },
  },
};
</script>
<style scoped>
.phoneTopBar {
  display: none;
}
.swiperWarper {
  width: 80%;
  margin: 0 auto;
  height: 50%;
  position: relative;
  top: -50px;
}
.videoImg {
  height: 100%;
  border-radius: 10px;
  margin-right: 30px;
}
.topBarWarper {
  z-index: 1000;
  cursor: pointer;
  height: 50px;
  width: 100%;
  background: #fff
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 15%,
      rgba(0, 0, 0, 0.1) 78%,
      rgba(179, 179, 179, 0.1) 100%
    );
  position: fixed;
  display: flex;
  box-shadow: 0 1px 0 #ffeb9d, 0 2px 0 #bda12e, 0 3px 0 #a67712, 0 4px 0 #f2be4b,
    0 10px 20px rgba(0, 0, 0, 0.2);
}
.logoWraper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 100%;
}
.logoWraper img {
  width: 100px;
  height: auto;
}
.contentWraper {
  font-size: 22px;
  line-height: 50px;
  color: #434343;
  display: flex;
  flex: 1;
}
.content {
  flex: 1;
  text-align: center;
}
.content:hover {
  color: #0067ff;
}
.languageWraper {
  display: flex;
  font-size: 18px;
  text-align: center;
  align-items: center;
  width: 200px;
  padding: 0 50px;
}
.leaguaeContent {
  flex: 1;
}
.currentColor {
  color: #0067ff;
}
.leaguaeContent:hover {
  color: #0067ff;
}
.active {
  color: #0067ff;
}
.phoneContactMessage {
  display: none;
}
@media screen and (max-width: 640px) {
  .topBarWarper {
    display: none;
  }
  .phoneContactMessage {
    display: flex;
    position: fixed;
    bottom: 100px;
    right: 50px;
    z-index: 999;
    flex-flow: column;
  }
  .imgBackGround {
    width: 170px;
    background: #fff;
    height: 170px;
    border-radius: 50%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.6);
  }
  .imgBackios {
    width: 400px;
    border-radius: 40px;
    height: 140px;
    display: inline-block;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    margin-left: 80px;
    line-height: 90px;
    text-align: center;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    cursor: pointer;
    background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 400%;
    border-radius: 60px;
    animation: animate 8s linear infinite;
  }

  @keyframes animate {
    0% {
      background-position: 0%;
    }

    100% {
      background-position: 400%;
    }
  }

  .imgBackios::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 400%;
    border-radius: 40px;
    opacity: 0;
    transition: 0.5s;
  }
  .phoneImgIos {
    height: 80%;
  }
  .phoneImg {
    height: 88%;
  }
  .swiperWarper {
    padding-top: 250px;
  }
  .phoneTopBar {
    display: block;
    position: fixed;
    top: 0px;
    width: 100%;
    height: 250px;
    background: #fff
      linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1) 15%,
        rgba(0, 0, 0, 0.1) 78%,
        rgba(179, 179, 179, 0.1) 100%
      );
    z-index: 999;
    box-shadow: 0 1px 0 #ffeb9d, 0 2px 0 #bda12e, 0 3px 0 #a67712,
      0 4px 0 #f2be4b, 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  .phoneWraper {
    margin: 0 100px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logoImgWraper {
    height: auto;
    width: 350px;
  }
  .showMoreWraper {
    width: 250px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .lineWraper {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  .showline {
    width: 150px;
    height: 2px;
    background: black;
    margin: 25px;
  }
  .phoneMoreWraper {
    position: fixed;
    top: 0px;
    z-index: 1000;
    height: 100%;
    background-color: #fff;
    background-image: linear-gradient(
        135deg,
        rgba(0, 0, 0, 0) 46%,
        rgba(0, 0, 0, 0.1) 46%,
        rgba(0, 0, 0, 0.1) 54%,
        rgba(0, 0, 0, 0) 54%
      ),
      linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1) 15%,
        rgba(0, 0, 0, 0.1) 78%,
        rgba(179, 179, 179, 0.1) 100%
      );
    background-size: 7px 7px, cover;
    -webkit-box-shadow: 0 -1px 0 #f2be4b inset, 0 -2px 0 #a67712 inset,
      0 -3px 0 #bda12e inset, 0 -4px 0 #ffeb9d inset;
    box-shadow: 0 -1px 0 #f2be4b inset, 0 -2px 0 #a67712 inset,
      0 -3px 0 #bda12e inset, 0 -4px 0 #ffeb9d inset;
    width: 100%;
    overflow-y: hidden;
  }
  .moreContent {
    padding: 100px 200px;
    height: 100%;
  }
  .logoImageWarper {
    display: flex;
    height: 160px;
    justify-content: space-between;
    align-items: center;
  }
  .logoImage {
    height: auto;
    width: 550px;
  }
  .closeImg {
    height: 120px;
  }
  .flexContent {
    padding: 100px 0;
    display: flex;
    flex-flow: column;
    font-size: 100px;
  }
  .content {
    text-align: start;
    line-height: 300px;
  }
  .languageWraper {
    width: 100%;
    font-size: 100px;
    padding: 0px;
    text-align: start;
  }
}
</style>
