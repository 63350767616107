// 注意：一定是 exports，不是 export，否则会报错，报错信息是下列的中的内容不是 string
module.exports = {
  topBarContent: {
    company: 'Betfair Sports',
    hotLeague: 'Các giải đấu nổi tiếng',
    business: 'Hợp tác kinh doanh',
    join: 'tham gia với chúng tôi',
    serve: 'Quá trình dịch vụ',
  },
  businessContent: {
    title: `Betfair Sports`,
    contentTitle:`Dịch vụ một cửa hàng đầu trong ngành của Betfair Sports cho phép bạn giành chiến thắng ngay từ điểm xuất phát ở Đông Nam Á.`,
    content:
      ' BETF Sports có hơn 20 năm kinh nghiệm trong ngành. Nơi đây quy tụ những tài năng về phát triển công nghệ, tiếp thị thương hiệu, tầm nhìn nghệ thuật, vận hành và quảng bá kinh doanh, đồng thời có vai trò thân thiết đối với thị trường Đông Nam Á. Tùy chỉnh nhiều bộ mẫu, hỗ trợ nhiều ngôn ngữ và tiền tệ khác nhau trong văn phòng trước và sau, tích hợp các công nghệ quan trọng trên nền tảng và cung cấp các dịch vụ toàn diện như quản lý hoạt động, phát triển web, phát triển ứng dụng di động và giao diện API. Các mặt hàng thể thao luôn sẵn có, đồng thời cung cấp dịch vụ trọn gói cho các khách hàng doanh nghiệp. Các đồng nghiệp của BETF Sports cung cấp cho bạn những sản phẩm thể thao điện tử chất lượng cao và phong phú nhất. Hơn 5000 cuộc thi thể thao điện tử và trực tuyến được tổ chức hàng tháng, bao gồm hơn 25 trò chơi thể thao điện tử chính thống như CS: GO, Dota 2 và League of Legends, cho phép khách hàng ngay lập tức có danh mục thể thao điện tử cạnh tranh nhất. BeTF Sports là sự lựa chọn hàng đầu cho Đại hội Thể thao Châu Á và mời bạn cùng nhau tạo nên sự rực rỡ và vượt qua đỉnh cao.',
  },
  list: {
    listfir:
      'Các sự kiện đầy đủ nhất, được hỗ trợ bởi các nhà cung cấp dữ liệu thể thao',
    listsec: 'Đội thương nhân thể thao ưu tú, mạnh nhất thế giới',
    listthi: 'Cách chơi phong phú, chọn và đặt cược theo ý muốn',
    listfor:
      'Tỷ lệ chấp nhất, bóng đang chơi ban đầu, phá vỡ truyền thống, tỷ lệ cược ba vị trí được tính toán một cách tinh vi',
    listfif:
      'Đặt cược trong một giây, trao giải thưởng ngay lập tức, giải quyết trước, dẫn đầu thế giới',
    listsix: 'Bảo vệ cấp cao nhất, trải nghiệm tuyệt đối an toàn và mượt mà',
  },
  btncontent: 'liên hệ chúng tôi',
  hotLeagueContent: {
    title: 'Các giải đấu nổi tiếng',
    football: '5 giải đấu bóng đá hàng đầu',
    nba: 'Trò chơi thể thao NBA',
    Tennis: 'Bốn chức vô địch quần vợt mở rộng',
    Volleyball: 'Giải vô địch bóng chuyền thế giới',
    Table: 'Giải vô địch bóng bàn thế giới',
    Virtual: 'Thể thao ảo',
  },
  businessCooperation: {
    title: 'Hợp tác kinh doanh',
    content:
      'Betfair Sports cung cấp các dịch vụ đa dạng cho các đối tác của chúng tôi, bao gồm mạng gói một cửa mạng tiền mặt, mạng gói mạng tín dụng, tài khoản mạng tín dụng để hợp tác, kết nối giao diện API, dễ kết nối và có thể kết nối trong vòng một ngày. Chúng tôi cũng cung cấp các chương trình hợp tác đa dạng Betfair Sports sẽ đáp ứng bất kỳ chương trình nào có thể khiến cả hai bên cùng có lợi. Chúng tôi sẽ giải quyết tất cả các vấn đề kỹ thuật cho bạn và để bạn tập trung vào hoạt động.',
    listfir: 'Phá bỏ phong cách ngành và đa dạng hóa các giải pháp',
    listsec: 'Cập bến giao diện API',
    listthi: 'Mạng gói mạng tín dụng',
    listfor: 'Mạng lưới tín dụng một tay',
    listfif: 'Mọi kế hoạch đôi bên cùng có lợi',
    listsix: '7 * 24 giờ dịch vụ độc quyền',
  },
  findBusiness: {
    title: 'Quá trình dịch vụ',
    Consult: 'Tư vấn gói ưu đãi',
    contract: 'Ký hợp đồng',
    representation: 'Chọn phiên bản đại lý độc quyền',
    modification: 'Đề xuất sửa đổi sản xuất',
    online: 'hoạt động trực tuyến chính thức',
    maintenance: 'bảo trì trang web theo dõi',
  },
  footerContent: {
    attract: '招商',
    content: 'Vui lòng bấm vào hình trực tiếp để được tư vấn',
  },
}
