// 注意：一定是 exports，不是 export，否则会报错，报错信息是下列的中的内容不是 string
module.exports = {
  topBarContent: {
    company: '必发体育',
    hotLeague: '热门联赛',
    business: '业务合作',
    join: '加入我们',
    serve: '服务流程',
  },
  businessContent: {
    title: `必发体育`,
    contentTitle:'行业领先一站式服务 让您赢在起跑点 东南亚市场占第一',
    content:
      ' BETF体育拥有超过20年的行业经验，集结技术开发、品牌行销、美术视觉、营运及业务推广人才，针对东南亚市场贴心订製多套模板、前后台支持各国语系及币别，平台整合关键技术，提供营运管理、Web开发、移动App开发、API介接等全方位服务。各种体育项目一应俱全，同时也提供企业客户一站式全套服务BETF体育同事提供您最丰富、最优质的电竞产品。每月超过 5000 多场电子竞技及线上赛事，让客户即刻拥有最高竞争力的电竞组合。 亚洲博奕首选BETF体育，邀您一起创造辉煌、超越巅峰。',
  },
  list: {
    listfir: '赛事最齐全，体育数据商支撑',
    listsec: '全球最强玩法，体育操盘精英团队',
    listthi: '玩法丰富，随心选择、随心投注',
    listfor: '盘口最多，独创滚球，打破传统，三位赔率精致精算',
    listfif: '一秒下注，即刻派奖，领跑全世界',
    listsix: '顶级防护，绝对安全、顺畅体验',
  },
  btncontent: '联系我们',
  hotLeagueContent: {
    title: '热门联赛',
    football: '足球五大联赛',
    nba: 'NBA 竞彩赛事',
    Tennis: '网球四大公开赛',
    Volleyball: '世界排球锦标赛',
    Table: '世界乒乓球冠军赛',
    Virtual: '虚拟体育',
  },
  businessCooperation: {
    title: '业务合作',
    content:
      '必发体育为我们的合作伙伴提供多样化服务，现金网一站式包网，信用网包网，信用网占成合作，API接口对接，简单易接，一天内可接好。我们还提供多样化的合作方案，任何可以使合作的双方互赢互利的方案，必发体育都会积级响应。我们会为你解决所有技术问题，让你专注运营。',
    listfir: '颠覆行业模式，多元化指定解决方案',
    listsec: 'API接口对接',
    listthi: '信用网包网',
    listfor: '信用网一手放成',
    listfif: '任何互赢互利的方案',
    listsix: '7*24小时的专属服务',
  },
  findBusiness: {
    title: '服务流程',
    Consult: '咨询优惠方案',
    contract: '签订合同计划',
    representation: '选择独家代理版型',
    modification: '提案制作修改',
    online: '正式上线运营',
    maintenance: '后续站点维护',
  },
  footerContent: {
    attract: '招商',
    content: '请直接点图进行咨询',
  },
}
