import Vue from 'vue'
import App from './App.vue'
import 'amfe-flexible'
import VideoPlayer from 'vue-video-player'
import VueI18n from 'vue-i18n'
// import cookie from './cookie.js'
// Vue.use(cookie)

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'zh', // 语言标识
  messages: {
    zh: require('./lang/zh'), //中文
    en: require('./lang/en'), //英文
    th: require('./lang/th'), //泰语
    vn: require('./lang/vn'), //越南语
    id: require('./lang/id'), //印尼
  },
})
// const i18n = new VueI18n({
//   locale: getCookie('PLAY_LANG', 'zh'), // 语言标识
//   messages: {
//     zh: require('./lang/zh'),
//     en: require('./lang/en'),
//   },
// })
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
Vue.config.productionTip = false
new Vue({
  render: (h) => h(App),
  i18n, //挂载vue上
}).$mount('#app')
