<template>
  <div class="contactMessage">
    <div class="contnetWraper">
      <div class="left">
        <div class="phoneNumber" style="max-width:500px">{{$t("footerContent.attract")}}</div>
        <div class="phoneNumber" @click="toTelegram()">
          <img class="iconImg" src="../assets/telegtamIcon.png" alt />
          <div>Telegram:@bifatiyu88</div>
        </div>
        <div class="phoneNumber" @click="toSkype()">
          <img class="iconImg" src="../assets/skypeIcon.png" alt />
          <div>Skype:tiyukefu2211@outlook.com</div>
        </div>
      </div>
      <div class="right">
        <div class="phoneNumber">
          <div>{{$t("footerContent.content")}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    toTelegram() {
      window.open("https://t.me/bifatiyu88");
    },
    toSkype() {
      window.open("skype:live:.cid.39767e85f851ff84?chat");
    },
  },
};
</script>
<style scoped>
.contactMessage {
  background: #8292a5;
  width: 80%;
  margin: 0 auto;
  bottom: 0;
  display: flex;
  font-size: 20px;
  line-height: 35px;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  z-index: 998;
}
.left {
  display: flex;
  width: 70%;
}
.right {
  flex: 1;
}
.contnetWraper {
  padding: 10px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.phoneNumber {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.iconImg {
  height: 28px;
  padding-right: 10px;
}
@media screen and (max-width: 640px) {
  .contnetWraper {
    display: none;
  }
}
</style>
