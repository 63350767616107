<template>
  <div>
    <div id="2" class="contentWarper">
      <div class="animationWrapper">
        <div class="animationImg"></div>
      </div>
      <div class="contentRight">
        <div class="contentTitle">{{$t("businessCooperation.title")}}</div>
        <div class="contentText">{{$t("businessCooperation.content")}}</div>
        <div class="contentBottom">
          <div class="squareWarper">
            <div class="square"></div>
          </div>
          <div class="bottomText">{{$t("businessCooperation.listfir")}}</div>
        </div>
        <div class="contentBottom">
          <div class="squareWarper">
            <div class="square"></div>
          </div>
          <div class="bottomText">{{$t("businessCooperation.listsec")}}</div>
        </div>
        <div class="contentBottom">
          <div class="squareWarper">
            <div class="square"></div>
          </div>
          <div class="bottomText">{{$t("businessCooperation.listthi")}}</div>
        </div>
        <!-- <div class="contentBottom">
          <div class="squareWarper">
            <div class="square"></div>
          </div>
          <div class="bottomText">{{$t("businessCooperation.listfor")}}</div>
        </div> -->
        <div class="contentBottom">
          <div class="squareWarper">
            <div class="square"></div>
          </div>
          <div class="bottomText">{{$t("businessCooperation.listfif")}}</div>
        </div>
        <div class="contentBottom">
          <div class="squareWarper">
            <div class="square"></div>
          </div>
          <div class="bottomText">{{$t("businessCooperation.listsix")}}</div>
        </div>
        <button class="contactBtn" @click="toTelegram()">{{$t("btncontent")}}</button>
      </div>
      <div class="contentLeft">
        <div class="imgWraper">
          <img class="companyLeftImg" src="../assets/computerImg.png" alt />
        </div>
        <div class="ipadWraper">
          <img class="ballImg" src="../assets/ballImg.png" alt />
          <img class="ipadImg" src="../assets/ipadImg.png" alt />
          <img class="phoneImg" src="../assets/phoneImg.png" alt />
        </div>
        <div class="shadowWraper">
          <img class="ipadShadow" src="../assets/ipadShadow.png" alt />
          <img class="phoneShadow" src="../assets/phoneShadow.png" alt />
          <img class="computerShadow" src="../assets/computerShadow.png" alt />
        </div>
      </div>
    </div>
    <contactMessage />
  </div>
</template>
<script>
import contactMessage from "./contactMessage.vue";
export default {
  components: {
    contactMessage,
  },
  data() {
    return {};
  },
  methods: {
    toTelegram() {
      window.open("https://t.me/bifatiyu88");
    },
  },
};
</script>
<style scoped>
.contentWarper {
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  width: 80%;
  margin: 0 auto;
  background-color: white;
  box-sizing: border-box;
  padding: 120px 50px 110px 50px;
}
.animationWrapper {
  position: absolute;
  height: 80%;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
}

.animationWrapper .animationImg {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url("../assets/bg-ani-1.png");
  background-size: contain;
  background-repeat: no-repeat;
  animation: scaleDraw 10s infinite ease-in-out;
  z-index: -1;
  overflow: hidden;
}
.contentLeft {
  height: 100%;
  position: relative;
  flex: 1;
  z-index: 2;
}
.contentRight {
  flex: 1;
  z-index: 998;
}
.contentTitle {
  font-size: 32px;
  font-family: Source Han Sans CN,serif;
  font-weight: bold;
  line-height: 46px;
  color: #001726;
}
.contentText {
  width: 650px;
  margin: 30px 0;
  font-size: 18px;
  font-family: Source Han Sans CN,serif;
  font-weight: 400;
  line-height: 29px;
  color: #062c59;
}
.contactBtn {
  padding: 0 50px;
  cursor: pointer;
  height: 64px;
  background: linear-gradient(180deg, #06448f 0%, #032c5e 100%);
  border-radius: 6px;
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
  border: none;
  color: #ffffff;
  margin-top: 30px;
}
.companyLeftImg {
  height: 620px;
}
.imgWraper {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.ipadWraper {
  display: flex;
  position: absolute;
  top: 150px;
  align-items: flex-end;
}
.ipadImg {
  height: 400px;
}
.phoneImg {
  height: 280px;
}
.ballImg {
  display: block;
  position: absolute;
  height: 135px;
  left: -100px;
  bottom: -50px;
  z-index: 998;
}
.companyLeftImg {
  animation: right-left-move 12s -2s infinite alternate-reverse both;
}

.ipadImg {
  animation: left-small-move 10s -3s infinite;
}
.phoneImg {
  animation: right-pig-move 8s infinite;
}
/* .ballImg {
  animation: left-pig-move 10s 2s infinite both;
} */
.ipadShadow {
  width: 300px;
}
.phoneShadow {
  width: 145px;
}
.computerShadow {
  width: 208px;
}
.shadowWraper {
  display: flex;
  position: absolute;
  bottom: -80px;
  left: -20px;
  z-index: -1;
}

@keyframes left-small-move {
  0%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  35% {
    -webkit-transform: translate3d(0, -8px, 0);
    transform: translate3d(0, -8px, 0);
  }
  65% {
    -webkit-transform: translate3d(8px, 0, 0);
    transform: translate3d(8px, 0, 0);
  }
}

@keyframes right-left-move {
  0% {
    -webkit-transform: translate3d(-9px, 0, 0);
    transform: translate3d(-9px, 0, 0);
  }
  35% {
    -webkit-transform: translate3d(3px, 0, 0);
    transform: translate3d(3px, 0, 0);
  }
  65% {
    -webkit-transform: translate3d(-6px, 0, 0);
    transform: translate3d(-6px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(9px, 0, 0);
    transform: translate3d(9px, 0, 0);
  }
}
@keyframes right-pig-move {
  0%,
  100% {
    -webkit-transform: translate3d(0, 4px, 0);
    transform: translate3d(0, 4px, 0);
  }
  35% {
    -webkit-transform: translate3d(-4px, 12px, 0);
    transform: translate3d(-4px, 12px, 0);
  }
  65% {
    -webkit-transform: translate3d(-12px, -4px, 0);
    transform: translate3d(-12px, -4px, 0);
  }
}
@keyframes left-pig-move {
  0%,
  100% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
  35% {
    -webkit-transform: translate3d(4px, -12px, 0);
    transform: translate3d(4px, -12px, 0);
  }
  65% {
    -webkit-transform: translate3d(12px, 4px, 0);
    transform: translate3d(12px, 4px, 0);
  }
}
@media (max-width: 1150px) and (min-width: 640px) {
  .shadowWraper {
    display: none;
  }
  .contentLeft {
    z-index: 2;
    top: 150px;
  }
}

@media screen and (max-width: 640px) {
  .contentLeft {
    position: absolute;
    left: 50%;
    top: 1000px;
  }
  .shadowWraper {
    display: none;
  }
  .animationWraper {
    display: none;
  }
  .contentWarper {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
  .imgWraper {
    display: flex;
    justify-content: center;
    margin-top: 500px;
    margin-left: 150px;
  }
  .contentTitle {
    font-size: 100px;
    text-align: center;
    padding: 100px 0;
  }
  .contentText {
    width: 100%;
    font-size: 60px;
    line-height: 120px;
  }
  .square {
    height: 50px;
    width: 50px;
    margin-top: 40px;
    margin-right: 40px;
  }
  .bottomText {
    font-size: 70px;
  }
  .contactWraper {
    width: 100%;
  }
  .contactBtn {
    font-size: 60px;
    min-height: 160px;
    border-radius: 20px;
    display: block;
    margin-top: 30px;
    padding: 0 30px;
    min-width: 400px;
  }
  .contentBottom {
    line-height: 150px;
  }
  .ipadWraper {
    top: 700px;
    margin-left: 140px;
  }
  .computerShadow {
    padding-left: 300px;
    width: 300px;
  }
}
</style>
